import React, { useContext } from 'react'
import { Alert } from 'components/common';
import LinearProgress from '@material-ui/core/LinearProgress';
import { AuthContext } from "context/authContext"
import { AdminContext } from "context/adminContext"
import { NewsContext } from "context/newsContext"

const Alerts = () => {
    const { loading: loadingAuth, error: errorAuth, success: successAuth } = useContext(AuthContext);
    const { loading: loadingAdmin, error: errorAdmin, success: successAdmin } = useContext(AdminContext);
    const { loading: loadingNews, error: errorNews, success: successNews } = useContext(NewsContext);

    return (
        <div>
            {errorAdmin && <Alert severity="error" title="Error" text={errorAdmin} />}
            {errorAuth && <Alert severity="error" title="Error" text={errorAuth} />}
            {errorNews && <Alert severity="error" title="Error" text={errorNews} />}
            {successAuth && <Alert severity="success" title="Success" text={successAuth} />}
            {successAdmin && <Alert severity="success" title="Success" text={successAdmin} />}
            {successNews && <Alert severity="success" title="Success" text={successNews} />}
            {loadingAuth && <LinearProgress color="secondary" />}
            {loadingAdmin && <LinearProgress color="secondary" />}
            {loadingNews && <LinearProgress color="secondary" />}
        </div>
    )
}

export default Alerts
