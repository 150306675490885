import React, { useState, useContext } from 'react'
import { Grid, TextFieldLabel, Avatar, SwitchLabel, DatePickerLabel } from 'components/common';
import { AuthContext } from "context/authContext"
import { NewsContext } from "context/newsContext"
import { unescape } from 'html-escaper';
import Button from '@material-ui/core/Button';
import { Link } from "utils/link"
import styled from 'styled-components';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import { Markdown } from 'components/mdxComponents/markdown';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import { Comments } from 'components'
const NewsCard = styled(Card)`
margin-top: 15px;
 max-width: 100%;
 padding : 2rem;
   @media only screen and (max-width: 767px) {
    padding: 0rem;
  }

`;
const AuthorSection = styled.div`
display: flex;
justify-content: flex-end;
/* flex-direction: column; */
/* align-items: flex-end; */
`



const NewsItem = ({ news, add, handleCancel }) => {

  const { handleUpdateNews, handleDeleteNews, crudComment } = useContext(NewsContext)
  const { user: loggedUser } = useContext(AuthContext)

  const isAdmin = loggedUser.role === 'admin' || false



  // useEffect(() => {
  //   setState(news)
  // }, [news])

  const [state, setState] = useState(news)
  const [edit, setEdit] = useState(add ? true : false)



  const handleClear = () => {
    setEdit(false)
    handleCancel()
  }
  const handleUpdate = event => {
    setState({
      ...state,
      [event.target.name]: event.target.value,
    })
  }

  const handleUpdateMd = md => {
    setState({
      ...state,
      md,
    })
  }

  const handleSubmit = () => {
    handleUpdateNews(news.id, state)
    setEdit(false)
  }

  const changeEdit = () => { setEdit(!edit) }


  const handleDelete = () => {
    handleDeleteNews(news.id)
  }
  return (
    <NewsCard >
      <CardHeader
        style={{ paddingBottom: 0 }}
        avatar={
          state?.author?.img && <Link to={`/users/${state?.author?.id}`}><Avatar type="users" name="avatar" edit={false} srcImg={state.author.img} src={state.author.avatar} alt={state.author.firstname} size="xs" /></Link>}
        action={isAdmin && <SwitchLabel
          persistant
          checked={edit}
          onChange={changeEdit}
          label="Edit"
        />}
        title={<Link to={`/users/${state?.author?.id}`}>{state?.author?.firstname}</Link>}
        subheader={<DatePickerLabel format={1} name="Date" value={state.date} edit={false} />}
      />

      <CardContent style={{ paddingTop: 0 }}
      >
        <TextFieldLabel title name="title" value={state.title} edit={edit} handleUpdate={handleUpdate} />

        <Markdown edit={edit} md={unescape(state.md)} handleUpdateMd={handleUpdateMd} />

      </CardContent>
      <CardActions>
        {edit && <>
          <Grid center item xs={4} >
            <Button
              data-testid="sign-up-button"
              type="button"
              variant="contained"
              color="inherit"
              onClick={handleClear}
            >Cancel
            </Button>
          </Grid>
          <Grid center item xs={4} >

            <Button
              data-testid="sign-up-button"
              type="button"
              variant="contained"
              color="primary"
              onClick={handleSubmit}
            >{news.id ? "Update" : "Create"}
            </Button>
          </Grid>
          <Grid center item xs={4} >

            {news.id && <Button
              data-testid="sign-up-button"
              type="button"
              variant="contained"
              color="secondary"
              onClick={handleDelete}
            >Delete
            </Button>}
          </Grid>
        </>}
      </CardActions>
      {!edit && <Comments newsId={news.id} comments={news.comments} crudComment={crudComment} />}
    </NewsCard>
  )
}

export default NewsItem