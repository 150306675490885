import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ContentContext } from "../context/contentContext";
import { AuthContext } from "../context/authContext";
import { StyledHeading } from "../components/styles/Docs";
import { Spinner, SwitchLabel } from "components/common";
import RightSidebar from "components/content/rightSidebar";
import styled from "styled-components";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import { Link } from "utils/link";
import NextPrevious from "components/content/NextPrevious";
import { Markdown } from "../components/mdxComponents/markdown"
import { Edit as EditIcon } from '@material-ui/icons';
import remarkGfm from "remark-gfm";
import MDEditor, { commands, ICommand, TextState, TextApi, MarkdownUtil } from '@uiw/react-md-editor';
import { useTheme } from '@material-ui/core/styles';
import { Button } from "@material-ui/core";

const RightSideBarWidth = styled("div")`
  display: block;
  width: 100%;
  max-width: 300px;
  justify-self: flex-end;
  @media only screen and (max-width: 991px) {
    display: none;
  }
`;
const Wrapper = styled("div")`
  display: flex;
  justify-content: space-around;
  padding-top: 20px;
  width: 100%;

  padding-left: 20px;
  @media only screen and (max-width: 991px) {
    padding-right: 20px;
  }
  @media only screen and (max-width: 767px) {
    display: block;
    padding-left: 2px;
    padding-right: 2px;
  }
`;
const Container = styled("div")`
  display: flex;
  flex-direction: column;
  /* justify-content: center; */

  padding-top: 20px;
  width: 100%;

  padding-left: 20px;
  padding-bottom: 50px;

  @media only screen and (max-width: 767px) {
    display: block;
    padding-left: 2px;
    padding-right: 2px;
  }
`;
const MdWrapper = styled("div")`
  margin-top: 50px;
  max-width: 900px;
  text-align: justify;
  /* min-width: 500px; */
  & > p {
    margin-bottom: 20px;
  }
  & > h2 {
    margin-bottom: 20px;
  }
`;

export const ContentPage = () => {
  const { getMd, md, loading } = useContext(ContentContext);
  const { user: loggedUser } = useContext(AuthContext)

  const isAdmin = loggedUser?.role === 'admin' ?? false
  const [edit, setEdit] = useState(false)

  const [localMd, setLocalMd] = useState("")
  const theme = useTheme();
  let { filepath } = useParams();

  useEffect(() => {
    getMd(filepath);
  }, [filepath]);

  useEffect(() => {
    md?.body && setLocalMd(md.body)
  }, [md]);
  console.log("mdobject :>> ", md);


  const handleUpdateMd = (md) => {
    setLocalMd(md)
  }
  return (

    <Container>
      <Breadcrumbs aria-label="breadcrumb">
        {filepath.split("/").map((path, i, arr) => (
          <Link

            to={`/content/${filepath.substring(
              0,
              filepath.indexOf(path) + path.length
            )}`}
            key={path}
          >
            {path}
          </Link>
        ))}
      </Breadcrumbs>
      <Wrapper>
        <div>
          {isAdmin &&
            <SwitchLabel
              persistant
              checked={edit}
              onChange={() => setEdit(!edit)}
              label="Edit"
              icon={<EditIcon color={edit ? "primary" : "disabled"} />}
            />
          }
          {md.title && <StyledHeading>{md.title}</StyledHeading>}
          {isAdmin && edit &&
            <Button color="primary" variant="outlined">
              <Link color="primary" to={`https://github.com/CodeAcademyBerlin/content/edit/master/${filepath}.md`}> Edit on Github </Link>
            </Button>}

          {loading && <Spinner size="50px" />}
          {/* {error && <p>{error}</p>} */}

          {!loading && localMd && (
            <MdWrapper>

              <div data-color-mode={theme.palette.type}  >
                {/* <MDEditor.Markdown source={md.body} /> */}
                <Markdown edit={edit} md={localMd} handleUpdateMd={setLocalMd} />
              </div>

            </MdWrapper>

          )}
        </div>
        <RightSideBarWidth>
          {md.headers && <RightSidebar headers={md.headers} />}
        </RightSideBarWidth>
      </Wrapper>
      <NextPrevious prev={md.prev} next={md.next} />
    </Container>

  );
};
