import React, { useContext, useEffect } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import { Layout } from 'components';
import { AuthContext } from 'context/authContext'
import {
  ProfilePage,

  CalendarPage,
  CohortPage,
  FamilyPage,
  AdminPage,
  ContentPage,
  MentorsPage,
  LandingPage,

} from 'pages'

const PrivateRoute = ({ children, ...rest }) => {
  const { isAuthenticated } = useContext(AuthContext)
  return (
    <Route
      {...rest}
      render={({ location }) =>
        isAuthenticated ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: location }
            }}
          />
        )
      }
    />
  );
}
const AdminRoute = ({ children, ...rest }) => {
  const { user } = useContext(AuthContext)
  return (
    <Route
      {...rest}
      render={({ location }) =>
        user?.role === 'admin' ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: location }
            }}
          />
        )
      }
    />
  );
}



const App = () => {
  const { appLoading } = useContext(AuthContext)

  const loader = document.querySelector('.loader');
  loader.style.display = 'none';

  useEffect(() => {
    const hideLoader = () => {
      if (appLoading === false)
        loader.style.display = 'none';
    };

    hideLoader()
  }, [appLoading]);

  return (
    <Router>
      <Layout>
        <Switch>
          <Route exact path={['/', '/login', '/register', '/resetpassword', '/forgotpassword']}>
            {!appLoading && <LandingPage />}
          </Route>
          <Route path='/content/:filepath+'>
            <ContentPage />
          </Route>
          <PrivateRoute path='/users/:userId'>
            <ProfilePage />
          </PrivateRoute>
          <PrivateRoute path='/cohorts/:cohortId'>
            <CohortPage />
          </PrivateRoute>

          <PrivateRoute path='/mentors'>
            <MentorsPage />
          </PrivateRoute>
          <PrivateRoute path='/calendar'>
            <CalendarPage />
          </PrivateRoute>
          <PrivateRoute path='/family'>
            <FamilyPage />
          </PrivateRoute>
          <AdminRoute path='/admin'>
            <AdminPage />
          </AdminRoute>
        </Switch>
      </Layout>
    </Router>
  );
}

export default App;
