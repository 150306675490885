import React, { useContext } from 'react';
import Tree from './tree';
import styled from 'styled-components';
import { AuthContext } from "context/authContext";
import { ContentContext } from "context/contentContext";
import List from '@material-ui/core/List';
import { ListItem } from 'components/common';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import EventIcon from '@material-ui/icons/Event';
import DomainIcon from '@material-ui/icons/Domain';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import SchoolIcon from '@material-ui/icons/School';
import { Link } from 'utils/link';
import Divider from '@material-ui/core/Divider';



const Grow = styled('div')`
flex-grow: 1
`;

const Sidebar = styled('aside')`
display: flex;
flex-direction: column;
width: 100%;
height: 100vh;
overflow-y: auto;
overflow-x: hidden;
position: fixed;
padding-left: 0px;
position: -webkit - sticky;
position: -moz - sticky;
position: sticky;
top: 0;
padding-right: 0;
-webkit-box-shadow: -1px 0px 4px 1px rgba(175, 158, 232, 0.4);
box-shadow: -1px 0px 4px 1px rgba(175, 158, 232, 0.4);

@media only screen and(max-width: 767px) {
  display: none;
}
`;


const SidebarLayout = ({ drawerWidth, handleDrawerOpen, location }) => {
    const { user } = useContext(AuthContext);
    const { tree } = useContext(ContentContext);

    // const [open, setOpen] = useState(false);

    // useEffect(() => {
    //     if (drawerWidth < 80)
    //         setOpen(false)
    // }, [drawerWidth])

    // const handleClick = () => {

    //     if (drawerWidth < 80) {
    //         handleDrawerOpen()
    //     }
    //     setOpen(!open);
    // };
    return (
        <Sidebar>
            <List style={{ maxWidth: '100%' }}>
                {/* <ListItem isactive={(location.pathname === '/').toString()} onClick={handleDrawerOpen} >
                    <ListItemIcon>
                        <MenuBookIcon />
                    </ListItemIcon>
                    {drawerWidth > 80 && <ListItemText primary="Content" />}
                    <ListItemSecondaryAction>
                        {drawerWidth > 80 && <IconButton onClick={handleClick} edge="end">
                            {open ? <ExpandLess /> : <ExpandMore />}
                        </IconButton>}
                    </ListItemSecondaryAction>
                </ListItem>
                <Collapse in={true} timeout="auto"
                unmountOnExit
                > */}
                {tree && <Tree edges={tree} location={location} />}
                {/* </Collapse> */}
                <Divider />
                <Link to='/mentors/'>
                    <ListItem isactive={(location.pathname === '/mentors/').toString()} >
                        <ListItemIcon>
                            <SchoolIcon htmlColor={location.pathname === '/mentors/' ? "#FFF" : undefined} />
                        </ListItemIcon>
                        {drawerWidth > 80 && <ListItemText primary="Mentors" />}
                    </ListItem>
                </Link>
                <Link to='/calendar/' >
                    <ListItem isactive={(location.pathname === '/calendar/').toString()} >
                        <ListItemIcon>
                            <EventIcon htmlColor={location.pathname === '/calendar/' ? "#FFF" : undefined} />
                        </ListItemIcon>
                        {drawerWidth > 80 && <ListItemText primary="Calendar" />}
                    </ListItem>
                </Link>
                <Link to='/family/' >
                    <ListItem isactive={(location.pathname === '/family/').toString()} >
                        <ListItemIcon>
                            <SupervisorAccountIcon htmlColor={location.pathname === '/family/' ? "#FFF" : undefined} />
                        </ListItemIcon>
                        {drawerWidth > 80 && <ListItemText primary="Family" />}
                    </ListItem>
                </Link>

                <Divider />
                <Grow />

            </List>
        </Sidebar>
    );
};

export default SidebarLayout;
