import React from 'react';
import ReactDOM from 'react-dom';
import './assets/fonts/Alenia.ttf';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ThemeProvider } from 'context/themeContext';
import { AuthProvider } from 'context/authContext'
import { AdminProvider } from 'context/adminContext'
import { ContentProvider } from 'context/contentContext'
import { NewsProvider } from 'context/newsContext'

import { createMemoryHistory } from 'history'
import {
  BrowserRouter as Router
} from 'react-router-dom';


window.process = { cwd: () => '' };


const history = createMemoryHistory()


if (process.env.NODE_ENV !== "development")
  console.log = () => { };

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider>
      <AuthProvider>
        <AdminProvider>
          <Router history={history}>
            <ContentProvider>
              <NewsProvider>
                <App />
              </NewsProvider>
            </ContentProvider>
          </Router>
        </AdminProvider>
      </AuthProvider>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
