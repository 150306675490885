import React from 'react';
import { Sidebar, ListItem } from 'components/styles/Sidebar';

const RightSidebar = ({ headers }) => {
    return (

        <Sidebar >
            <ul className={'rightSideBarUL'}>
                <li className={'rightSideTitle'}>CONTENTS</li>
                {headers.map((header, index) =>
                    <ListItem key={index} to={`#${header.text.toLowerCase().replace(/\s+/g, '-').replace(/:|\(|\)|\./g, '')}`} level={1}>
                        {header.text}
                    </ListItem>)
                }
            </ul>
        </Sidebar>
    );
}



export default RightSidebar;
