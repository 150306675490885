export const updateUserKeys = () => {
  const userKeys = [
    "username",
    "firstname",
    "about",
    "lastname",
    "avatar",
    "img",
    "gitHub",
    "linkedIn",
    "photoConsent",
    "shareProfile",
  ];

  return {
    admin: [
      ...userKeys,
      "cablifecycle",
      "email",
      "gmail",
      "start_date",
      "end_date",
      "course_length",
      "course",
    ],
    user: userKeys,
  };
};
export const updateCohortKeys = () => {
  const cohortKeys = ["aka", "img"];

  return {
    admin: [
      ...cohortKeys,
      "name",
      "logo",
      "start_date",
      "end_date",
      "calendarWeb",
      "calendarData",
    ],
    user: cohortKeys,
  };
};

export const usersColumns = (smUp) => {
  const columns = [
    "img",
    "username",
    "gitHub",
    "course",
    "course_length",
    "firstname",
    "lastname",
    "start_date",
  ];
  const columnsXs = ["firstname", "lastname", "course"];
  return smUp
    ? {
      user: columns,
      admin: [...columns, "email", "cablifecycle", "photoConsent"],
    }
    : {
      user: columnsXs,
      admin: columnsXs,
    };
};
export const cohortsColumns = (smUp) => {
  const columns = ["img", "name", "aka", "start_date", "count"];
  const columnsXs = ["name", "start_date", "count"];
  return smUp
    ? {
      user: columns,
      admin: [...columns],
    }
    : {
      user: columnsXs,
      admin: columnsXs,
    };
};

// export const hasAccess = (access) => {
// const { user } = useContext(AuthContext)

// if (access == 'public') return true
// else if (user) {
//   if (user.role === 'admin') return true
//   if (user.course == 'all') return true
//   if (access == 'all') return true
//   else if (access == user.course) return true
//   else return false
// }

// else return false
// }

export const courseString = {
  data: "Data Science",
  web: "Web Development",
  all: "Full Access",
  undefined: "Visitor",
};

export const rolesDropDown = [
  { value: "trial", label: "Trial" },
  { value: "recuter", label: "Recuter" },
  { value: "admin", label: "Admin" },
  { value: "user", label: "User" },
  { value: "mentor", label: "Mentor" },
];

export const courseLengthDropDown = [
  { value: "3 months", label: "3 months" },
  { value: "4 months", label: "4 months" },
  { value: "5 months", label: "5 months" },
];
export const cabLifecyclehDropDown = [
  { value: "Client", label: "Student" },
  { value: "Alumni", label: "Alumni" },
  { value: "Lead", label: "Lead" },
  { value: "Out", label: "Out" },
  { value: "Staff", label: "Staff" },
];

export const courseStringDropDown = Object.keys(courseString).map((course) => {
  return { value: course, label: courseString[course] };
});

export const dataTypes = {
  course: {
    type: "dropdown",
    options: courseStringDropDown,
    id: "course",
    numeric: false,
    sortable: true,
  },
  course_length: {
    type: "dropdown",
    options: courseLengthDropDown,
    id: "course_length",
    numeric: false,
    sortable: true,
  },
  cablifecycle: {
    type: "dropdown",
    options: cabLifecyclehDropDown,
    id: "cablifecycle",
    numeric: false,
    sortable: true,
  },
  role: {
    type: "dropdown",
    options: rolesDropDown,
    id: "role",
    numeric: false,
    sortable: true,
  },
  start_date: {
    type: "date",
    edit: true,
    id: "start_date",
    numeric: false,
    sortable: true,
  },
  end_date: {
    type: "date",
    edit: true,
    id: "start_date",
    numeric: false,
    sortable: true,
  },
  id: {
    type: "string",
    edit: false,
    id: "course",
    numeric: false,
    sortable: false,
  },
  email: {
    type: "string",
    edit: false,
    id: "id",
    numeric: false,
    sortable: true,
  },
  firstname: {
    type: "string",
    label: "Firstname",
    id: "firstname",
    numeric: false,
    sortable: true,
  },
  lastname: { type: "string", id: "lastname", numeric: false, sortable: true },
  name: { type: "string", id: "name", numeric: false, sortable: true },
  gitHub: {
    type: "link",
    link: "https://github.com/<link_value>",
    id: "gitHub",
    numeric: false,
    sortable: false,
  },
  avatar: { type: "avatar", id: "avatar", numeric: false, sortable: false },
  logo: { type: "avatar", id: "logo", numeric: false, sortable: false },
  img: { type: "avatar", id: "img", numeric: false, sortable: false },
  photoConsent: {
    type: "boolean",
    label: "Photo Consent",
    id: "photoConsent",
    numeric: false,
    sortable: false,
  },
};

export const mentors = [
  {
    name: "Raul",
    calendly: "https://calendly.com/raulcodeacademy/30min",
    course: "web",
  },
  {
    name: "Emily",
    calendly: "https://calendly.com/emilycodeacademy/30min",
    course: "all",
  },
  {
    name: "Mouaid",
    calendly: "https://calendly.com/cab-mouaid/30min",
    course: "data",
  },
  {
    name: "Janina",
    calendly: "https://calendly.com/janina-codeacademyberlin/30min",
    course: "data",
  },
  {
    name: "Jost",
    calendly: "https://calendly.com/cab-jost/meetin",
    course: "all",
  },
  {
    name: "Lucas",
    calendly: "https://calendly.com/cab-lucas/30min",
    course: "web",
  },
];
